import React, { useState } from "react";

import "./notioptions.scss";
import Restart from "../restart/restart";

const NotiOptions = (props:any) => {
  const eventOptions = [
    {
      text: "Too many notifications",
      id: 1,
    },
    {
      text: "Missing notifications",
      id: 2,
    },
     {
      text: "Something else",
      id: 3,
    }
   
  ];


function restart(){
return <div className="noti-restart">
    
    <button className="show-more-link restart-workflow" onClick={props.actionProvider.handleNotifications} >
          Go back
    </button>   

    <button className="show-more-link restart" onClick={props.actionProvider.handleOptions} >
          Start from top
    </button>

    <button className="show-more-link feedback" onClick={props.actionProvider.showFeedback} >
          Share feedback
    </button>
</div>
}

 const [activeIndex, setActiveIndex] = useState(null);
const [isDisabled, setIsDisabled] = useState(false);
const [isClicked, setIsClicked] = useState(false);
const [optionClicked, setOptionClicked] = useState(0);
   const [intitialClassName, setClassName] = useState("option-button");
  const buttonsMarkup = eventOptions.map((option:any,index:any) => (
    <button key={option.id} onClick={()=>{setIsDisabled(!isDisabled);setActiveIndex(index);setClassName("option-inactive");

switch(option.id){
case 1:setOptionClicked(1);props.actionProvider.displaySubscriptions("Notification"); break;
case 2:setOptionClicked(2);props.actionProvider.displaySubscriptions("Notification"); break;
case 3:
      setOptionClicked(3);setIsClicked(true);window.open('https://experienceleague.adobe.com/home?support-tab=home#support'); break;
}



}} className={activeIndex === index?"change-buttons":intitialClassName}  disabled={isDisabled}>
      {option.text}
    </button>
  ));


  return <div className="options-container">{buttonsMarkup}
  {isClicked?restart():<></>}
  {optionClicked!==3?<Restart startfromtop={props.actionProvider.handleOptions} showfeedback = {props.actionProvider.showFeedback}/>:<></>}
</div>;
};

export default NotiOptions;
