import React, { useState } from "react";
import Restart from "../restart/restart"; // Import the Restart component

import "./eventoptions.scss";

const EventOptions = (props:any) => {
  const eventOptions = [
    {
      text: "Search by Event ID",
      handler: props.actionProvider.findEventId,
      id: 1,
    },
    {
      text: "Show ongoing Issues",
      handler: props.actionProvider.findOngoingCSOs,
      id: 2,
    },
    {
      text: "Show ongoing Maintenances",
      handler: props.actionProvider.findOngoingCMRs,
      id: 3,
    },
     {
      text: "Show last 7 days Issues",
      handler: props.actionProvider.findLast7DaysCSOs,
      id: 4,
    },
    {
      text: "Show last 7 days Maintenances",
      handler: props.actionProvider.findLast7DaysCMRs,
      id: 5,
    }
   
  ];






 const [activeIndex, setActiveIndex] = useState(null);
const [isDisabled, setIsDisabled] = useState(false);
 const [intitialClassName, setClassName] = useState("option-button");
  const buttonsMarkup = eventOptions.map((option:any,index:any) => (
    <button key={option.id} onClick={()=>{setIsDisabled(!isDisabled);setActiveIndex(index);option.handler();setClassName("option-inactive");}} className={activeIndex === index?"change-buttons":intitialClassName}  disabled={isDisabled}>
      {option.text}
    </button>
  ));


  return <div className="options-container">{buttonsMarkup}

  <Restart startfromtop={props.actionProvider.handleOptions} showfeedback = {props.actionProvider.showFeedback}/>
  </div>;
};

export default EventOptions;
