import React, { useState } from "react";
import YesFb from '../../image/thumbsup.png';
import NoFb from '../../image/thumbsdown.png';
import OMEGA from '../../omega';
import './feedback.scss';
import axios from "axios";
import { URLS } from "../../constants";
function FeedbackForm(props:any) {

 
  const [formData, setFormData] = useState({
    feedback: "",
    description: "",
  });
    const [submit, setSubmit] = useState(false);
   const [close, setClose] = useState(false);


    const eventDefaultValue = {
    element: "Feedback Form",
    type: "form",
    action: "click",
    widget: {
      name: "Feedback",
      type: "form"
    },
    feature: "Feedback Form Usage"
  }
  function  omega_capture(element:any, widget_name:any, widget_type:any){
     OMEGA.init().updateAndTrack("event", [{
      ...eventDefaultValue,
      element: element,
      widget: {
        name: widget_name,
        type: widget_type
      },
    }]);
  }

  const handleFeedbackClick = (choice:any) => {
    omega_capture("Feedback - Helpful: "+choice, "Feedback Form", "form");
    setFormData({ ...formData, feedback: choice });
  };

  const handleInputChange = (e:any) => {
    if (e.target.value.length <= 1000) {
      setFormData({ ...formData, description: e.target.value });
    }
  };

  const handleSubmit = (e:any) => {
      //sessionStorage.setItem("feedback","true")
      setSubmit(true);
      omega_capture("Feedback Submitted: Helpful: "+formData.feedback+" Description: "+formData.description, "Feedback Form", "form");
      e.preventDefault();

      const imsProfile:any = sessionStorage.getItem('adobeid_ims_profile');
      const profile:any = JSON.parse(imsProfile);

      var data:any = {

        helpful: formData.feedback,
        feedback: formData.description,
        userid: profile.userId,
        email: profile.email,
      }
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + window.adobeIMS.getAccessToken().token,
      };
      // axios post
      axios.post(URLS.FEEDBACK_URL, data,{headers})
      .then(response => {
        if(response.status === 200){
          console.log("Feedback submitted:", response.data);
          props.actionProvider.handleFeedbackSubmit();
          setFormData({ feedback: "", description: "" });
        }else{
          console.error("There was an error submitting the feedback!");
        }
      })
      .catch(error => {
        props.actionProvider.handleFeedbackFailure();
        setFormData({ feedback: "", description: "" });
        console.error("There was an error submitting the feedback!", error);
      });

      // console.log("Feedback submitted:", formData);
      // props.actionProvider.handleFeedbackSubmit();
     
  };

  return (
<>

    <div  className={submit||close?"feedback-div nodisplay":"feedback-div"}>
    <div className="feedback-header"></div>
        <div className="feedback-cross" onClick={() =>{omega_capture("Feedback Form Closed","Feedback Form", "form");
                                                 setClose(true) }}></div>
     
      {/* <p className="mandatory">*</p> */}
      <p className={"initial-text"}>Did you find what you were looking for?   </p>
      <div className="mandatory">*</div>

      <div className="feedback-button-div">
        <button
          className={formData.feedback==="Yes"?"feedback-button-yes yes-active":"feedback-button-yes"}
          onClick={() => handleFeedbackClick("Yes")}
          disabled={submit}
        >
          <img
            src={YesFb}
            alt="Yes"
            style={{ width: "60px" }}
          />
        </button>

        <button
          className= {formData.feedback==="No"?"feedback-button-no no-active":"feedback-button-no"}
          onClick={() => handleFeedbackClick("No")}
          disabled={submit}
        >
          <img
            src={NoFb}
            alt="No"
            style={{ width: "60px" }}
          />
        </button>
      </div>
      <form className="form-submit" onSubmit={handleSubmit}>
        {/* <p>Please provide us with feedback to improve your experience</p> */}
        <textarea  className="feedback-textarea"
          placeholder={`Tell us more about your experience  (optional)`} 
          value={formData.description}
          onChange={handleInputChange}
          disabled={submit}
        ></textarea><span className="charcount">
        {formData.description.length}/1000
      </span>
        <button className={submit || formData.feedback===""?"feedback-submit submit-disabled":"feedback-submit"}
          type="submit"
          disabled={submit}
        >
          Submit Feedback
        </button>
      </form>

    </div>
{/* {submit?sessionStorage.setItem("feedback","true"):()=>{sessionStorage.setItem("feedback","false");sessionStorage.setItem("feedbackCount",sessionStorage.getItem("feedbackCount")?parseInt(sessionStorage.getItem("feedbackCount") as string)+1+"":"1")}} */}
{/* <div> {submit?"Your feedback has been recorded. Thank you.":""}  </div> */}
{/* {restart()} */}
</>
  );
}

export default FeedbackForm;
