import React, { useContext, useEffect } from 'react';
import './helpEvents.scss'
import { ConfigContext } from "../../App";
import parse from 'html-react-parser';


function HelpEvents() {
    const locales = useContext(ConfigContext).appConfig.languageData;

    return (
      <div className="help-landing">
         <div className="help-banner">
                  <div className="landing-screen-row">
                      <div className="landing-screen-image-column">
                          <div className="landing-screen-image"></div>
                      </div>
                      <div className="landing-screen-text-column">
                          {/* <p className="stay-up-to-date">{locales.stay_up_to_date}</p> */}
                          <h1>{locales.web_title}</h1>
                          <p className="about-status-adobe">
                              {parse(locales.about_status_adobe)}
                              <div>
                              <h3><span className='slack-icon'>{parse(locales.event_identification)}</span></h3>
                              <span>{parse(locales.event_glossary)}</span>
                          </div>
                          </p>
                          
        
                      </div>
                  </div>
              </div>
      
      <div className="event-heading">
      <h1 className="on-boarding-header-help">{locales.event_identification}</h1>
    
      </div>
      {/* <div className="image-event">
      <div className="landing-event-image"></div>
        </div> */}
      
      <div className="event-heading">
          <div className="event-steps" >
          <p className="step-header-event">{parse(locales.new_text)}</p>
            {/* <p className="step-header-event"><i className="event-icon available"></i>{parse(locales.event_available)}</p>
            <div className="image-event">
      <div className="landing-event-available-issue"></div>
        </div> */}
              <p className="step-header-event-issue event-header-new">{parse(locales.issues)}</p>
              {/* <ul>
              <li>{locales.issue_description}</li>
              </ul> */}
               <p className="step-header-event">{parse(locales.issue_description)}</p>
              <div className="image-event">
      <div className="landing-event-image-issue"></div>
        </div>
              <p className="step-header-event">{parse(locales.issue_severity)}</p>
              <ul>
                      <li><i className="event-icon potential"></i>{parse(locales.potential_issue)}</li>
                      <li><i className="event-icon major"></i>{parse(locales.major_issue)}</li>
                      <li><i className="event-icon minor"></i>{parse(locales.minor_issue)}</li>
                      <li><i className="event-icon trivial"></i>{parse(locales.trivial_issue)}
                      <ul>
                      <li className="step-header-event">{parse(locales.trivial_new)}</li></ul>
                      </li>
                      
              </ul>
          </div>
      </div>
      <div className="event-heading">
          <div className="event-steps" >
              <p className="step-header-event">{parse(locales.issue_status)}</p>
              <ul>
              <li>
              <span className="event-icons">
                        <i className="event-icon major"></i>
                        <i className="event-icon minor"></i>
                    </span>
                    {parse(locales.opened_issue)}
                </li>
   
              {/* <li><i className="event-icon major"></i><i className="event-icon minor"></i>{parse(locales.opened_issue)}</li> */}
                  {/* <li><i className="event-icon major"></i><i className="event-icon minor"></i>{parse(locales.opened_issue)}</li> */}
                  <li><i className="event-icon trivial"></i>{parse(locales.closed_issue)}</li>
                  <li><i className="event-icon trivial"></i>{parse(locales.dismissed_issue)}</li>
              </ul>
          </div>
      </div>
      <div className="event-heading">
          <div className="event-steps" >
              <p className="step-header-event-issue event-header-new">{parse(locales.maintenances)}</p>
              {/* <ul>
                    <li>{parse(l
                    ocales.maintenance_issue)}</li>
              </ul> */}
               <p className="step-header-event">{parse(locales.maintenance_issue)}</p>

          </div>
          <div className="image-event">
      <div className="landing-event-image-maintenance"></div>
        </div>
      </div>
      <div className="event-heading">
          <div className="event-steps" >
              <p className="step-header-event">{parse(locales.maintenance_severity)}</p>
              <ul>
                  <li><i className="event-icon maintenance"></i>{parse(locales.urgent_issue)}</li>
                  <li><i className="event-icon maintenance"></i>{parse(locales.normal_issue)}</li>
              </ul>
          </div>
      </div>
      <div className="event-heading">
          <div className="event-steps" >
              <p className="step-header-event">{parse(locales.maintenance_status)}</p>
              <ul>
                  <li><i className="event-icon maintenance"></i>{parse(locales.scheduled_maintenance)}</li>
                  <li><i className="event-icon maintenance"></i>{parse(locales.started_maintenance)}</li>
                  <li><i className="event-icon canceled_maintenance"></i>{parse(locales.completed_maintenance)}</li>
                  <li><i className="event-icon canceled_maintenance"></i>{parse(locales.cancelled_maintenance)}</li>
              </ul>
          </div>
      </div>
      <div className="event-heading">
          <div className="event-steps" >
              <p className="step-header-event-issue event-header-new">{parse(locales.announcements)}</p>
              {/* <ul>
                  <li><i className="event-icon announcement"></i>{parse(locales.announcement_issue)}</li>
              </ul> */}
              <p className="step-header-event">{parse(locales.announcement_issue)}</p>
              <p className="step-header-event">{parse(locales.announcement_example)}</p>
          </div>
      </div>
      <div className="image-event">
      <div className="landing-event-image-announcements"></div>
        </div>

      <div className="event-heading">
          <div className="event-steps" >
              <p className="step-header-event">{parse(locales.announcement_status)}</p>
              <ul>
                  <li><i className="event-icon announcement"></i>{parse(locales.announcement_open_issue)}</li>
                  <li><i className="event-icon closed"></i>{parse(locales.announcement_closed_issue)}</li>
              </ul>
          </div>
      </div>
      <div className="event-heading">
          <div className="event-steps" >
              <p className="step-header-event-issue event-header-new">{parse(locales.new_available)}</p>
              {/* <ul>
                  <li><i className="event-icon announcement"></i>{parse(locales.announcement_issue)}</li>
              </ul> */}
              <p className="step-header-event">{parse(locales.available_issue)}</p>
              <div className="image-event">
      <div className="landing-event-available-issue"></div>

        </div>
        {/* <p className="step-header-event">{parse(locales.new_available_2)}</p> */}
              <ul>
              <p className="step-header-event"><i className="event-icon available"></i>{parse(locales.event_available)}</p>
              </ul>
          </div>
      </div>
      

  </div>
    );
}
  
export default HelpEvents;