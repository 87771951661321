import { ConfigContext } from "../../App";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import OMEGA from '../../omega';
import { SHOW_HELP } from "../../constants";
import { render } from "enzyme";
export default function MenuList(props:any) {

  const eventDefaultValue = {
    element: "help menu initiated",
    type: "Button",
    action: "click",
    widget: {
      name: "Help Menu",
      type: "button"
    },
    feature: "Help Menu Click"
  }

  const histroyAPI = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const [listening, setListening] = useState(false);
  const locales = useContext(ConfigContext).appConfig.languageData;

  useEffect(() => {
    
    listenForOutsideClicks();
    return () => {
    document.removeEventListener(`click`,()=>{});    
    }
   
  }, [isOpen]);

  function listenForOutsideClicks(
  ) {
      if (listening) return
      if (!menuRef.current) return
      setListening(true);
        document.addEventListener(`click`, (evt) => {
          const cur:any = menuRef.current
          const node:any = evt.target
          if(!cur) return;
          if (cur.contains(node)) {return}
          else setIsOpen(false)
        },{ passive: true})  
  }

  function omega_capture(element: any, widget_name: any, widget_type: any){
    OMEGA.init().updateAndTrack("event", [{
      ...eventDefaultValue,
      element: element,
      widget: {
        name: widget_name,
        type: widget_type
      },
    }]);
  }

  function redirectNav(){
    omega_capture("help user guide initiated","Help Menu","Navigation");
    histroyAPI.push("/nav"); 
    if(props?.navstate){
      props.navstate(1);
    }
  }

  function renderHelpDropdown(){
 
    if(JSON.parse(SHOW_HELP)){
      return <li onClick={()=>{histroyAPI.push("/help/proactivesubscriptions") }}><span  className = "docsicon"></span><span className="menutext">{locales.subscription_how_to}</span></li>
    }
    return <></>;
  }

  function renderHelpEventsDropdown(){
    if(JSON.parse(SHOW_HELP)){
      return <li onClick={()=>{histroyAPI.push("/help/eventglossary") }}><span  className = "docsicon"></span><span className="menutext">{locales.event_identification}</span></li>
    }
    return <></>;
  }
  
  function renderCFSEventsDropdown(){
    if(JSON.parse(SHOW_HELP)){
      return <li onClick={()=>{histroyAPI.push("/help/cfs") }}><span  className = "docsicon"></span><span className="menutext">{locales.cfs}</span></li>
    }
    return <></>;

  }
  return (
      <button className="pns-link subscribe-banner" >
      <span className="help-banner" ref={menuRef} onClick={() => {
              setIsOpen(!isOpen);
              OMEGA.init().updateAndTrack("event", [{
                ...eventDefaultValue,
                //element: "My Events",
              }]);

            }}> {props.isMobile?<span className="help-icon"></span>:<><span className="text">{locales.help}</span><span className="help-icon"></span></>}</span>
             
                <div className={isOpen?"ShowHelpMenu":"HideHelpMenu"}>

               <div className="modal-help" id="helpModal">
                  <ul className="helpmenu">
                    <li onClick={()=>{redirectNav()}}><span  className = "docsicon"></span><span className="menutext">{locales.guided_tour}</span></li>
                    {renderHelpDropdown()}
                    {renderHelpEventsDropdown()}
                    {/* {renderCFSEventsDropdown()} */}
                    <li onClick={()=>{omega_capture("help developer's guide initiated","Help Menu","Developer's Guide"); window.open("https://developer.adobe.com/adobe-status/", '_blank');}}><span  className = "externallink"></span><span className="menutext">{locales.status_api}</span></li>
                  </ul>
                </div>:<></>

              </div>
              
      </button>
    );
}
