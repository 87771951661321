import React, { useState } from 'react';
import Select from 'react-select'; 

export default function EventDropdown(props:any) {

   const [events, setEvents] = useState([]);
 
  const suboptions = [
    {
      text: "Ok",
      id: 1,
    },
    {
      text: "Cancel",
      id: 2,
    },
  ];
    const [activeIndex, setActiveIndex] = useState(null);
    const [isDisabled, setIsDisabled] = useState(false);
   const [intitialClassName, setClassName] = useState("option-button");
  const buttonsMarkup = suboptions.map((option:any,index:any) => (
    <button key={option.id} onClick={()=>{setIsDisabled(!isDisabled);setActiveIndex(index); props.actionProvider.handleEventFilter(events);setClassName("option-inactive");}} className={activeIndex === index?"change-buttons":intitialClassName} disabled={isDisabled}>
      {option.text}
    </button>
  ));

 const [selectedOption, setSelectedOption] = useState(null);
   const [inputValue, setInputValue] = useState("");

 const handleChange = (option: any) => {
    
    props.actionProvider.resetState();
    setSelectedOption(option);
    setEvents(option);
  };


function getEvents(events:any){
  
    let eventList:any =[];
    var finaleventList: any = [];
    
    eventList = events;

    if (eventList && eventList.length) {
         eventList.forEach((event: any) => {
            var record = finaleventList.find((item:any) => item.value === event.incidentId)
            if(record){
                if(record.productInfo.find((productitem:any) => productitem.productId === event.productId)){
                    return;
                }else{
                record.productInfo.push({productId: event.productId, productName: event.name});
                }
            }
            else{
            finaleventList.push ({
                value: event.incidentId, label: event.incidentId, productInfo:[{productId: event.productId, productName: event.name}]
            });
           }
        });
    }

    return finaleventList;
    
}


  return (
   <><div>
    <Select
            isMulti
            value={selectedOption}
            noOptionsMessage={() => "Not found"} 
            onChange={(option) => handleChange(option)}
            options={getEvents(props.state.ongoingEvents)}
            isSearchable={true}
            placeholder="Enter an Event ID"
            isDisabled={isDisabled} 
            inputValue={inputValue} // Ensure input value is controlled
            onInputChange={(value) => setInputValue(value)} // Update input value state
            menuIsOpen={inputValue.length > 0}
            filterOption={(option, input) => 
                option.label.toLowerCase().includes(input.toLowerCase())
              }
            styles={{
              menuList: (provided) => ({
                ...provided, 
                overflowY: "auto", 
              }),
            }}
        />
    <br/>
    <div className="options-container">{buttonsMarkup}</div>
    <br/>
    </div></> 
  );
}
