import React from "react";

import "./restart.scss";


const Restart = (props:any) => {

  return <div className="restart-container">

    <button className="show-more-link restart" onClick={props.startfromtop} >
          Start from top
    </button>

    <button className="show-more-link feedback" onClick={props.showfeedback} >
          Share feedback
    </button>

  </div>;
};
export default Restart;