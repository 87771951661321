import React, { useEffect, useState } from 'react';
import { EventService } from '../../models/event-service';
import "./showevents.scss";
import moment from 'moment';
import { useHistory } from "react-router-dom";
export default function ShowEvents(props:any) {
    const histroyAPI = useHistory();
 function redirectEvent(url: any) {
        histroyAPI.push({
            pathname: url,
            state: {
                tab: "events",
                eventType: "all"
            }
        });
    }

  const [ongoingCSOs, setOngoingCSOs] = useState([]);
  const [historicCSOs, setHistoricCSOs] = useState([]);
  const [ongoingCMRs, setOngoingCMRS] = useState([]);
  const [historicCMRs, setHistoricCMRs] = useState([]);
  const [visibleCount, setVisibleCount] = useState(3);
  const[onGoingEvents, setOnGoingEvents] = useState(props.state.eventprop || []);
  
  const[historicEvents, setHistoricEvents] = useState([]);
    const [loading, setLoading] = useState(true);
  const eventService: EventService = EventService.init();
 

const showMore = () => {
    setVisibleCount((prevCount) => {return Math.min(prevCount + 3, onGoingEvents.length)});
  };
  
const showMoreEvents = (events:any) =>{
 setVisibleCount((prevCount) => {return Math.min(prevCount + 3, events.length)});
}

const showMoreHistoric = () => {
    setVisibleCount((prevCount) => {return Math.min(prevCount + 3, historicEvents.length)});
  };

 useEffect(()=>{
        
    const [servicePromise]: any = [eventService.fetch()];
    Promise.all([servicePromise])
      .then(() => {

            if (props.state.findOngoingCSOs || props.state.findOngoingCMRs) {
                let ongoingEvents:any = eventService.getOnGoingEventsByType();
                let ongoingCSOs = ongoingEvents.filter((event:any)=>!event.type.includes("maintenance"));
                let ongoingCMRs = ongoingEvents.filter((event:any)=>event.type.includes("maintenance"));
                let allOngoingCSOs:any = [];
                ongoingCSOs.forEach((cso:any)=>{cso.list.forEach((csoEvent:any)=>{allOngoingCSOs.push(csoEvent)})});
                let allOngoingCMRs:any = [];
                ongoingCMRs.forEach((cmr:any)=>{cmr.list.forEach((cmrEvent:any)=>{allOngoingCMRs.push(cmrEvent)})});
                  let filteredOngoingCSOs: any  =  [], filteredOngoingCMRs: any = [];
                if((props.state.findOngoingCSOs||props.state.findOngoingCMRs) && props.state?.regionprop?.length && props.state?.myCustomProperty?.length){
                    let tempregArr:  any =[];
                    let tempPrdArr:  any = [];
                    props.state.regionprop.forEach((region:any)=>{if(region.label==="All"){tempregArr = region.value}else{tempregArr.push(region.value)}});
                    props.state.myCustomProperty.forEach((prd:any)=>{tempPrdArr.push(prd.value)});
                    allOngoingCSOs.forEach((cso:any)=>{
                            if(cso.regions  && Object.keys(cso.regions).length){
                                if(Object.keys(cso.regions).some(value => tempregArr.includes(value))){
                                    if(tempPrdArr.length && tempPrdArr.includes(cso.productId)){
                                        filteredOngoingCSOs.push(cso);
                                    }
                                
                                }
                            }

                    })
                    allOngoingCMRs.forEach((cmr:any)=>{
                            if(cmr.regions  && Object.keys(cmr.regions).length){
                                if(Object.keys(cmr.regions).some(value => tempregArr.includes(value))){
                                    if(tempPrdArr.length && tempPrdArr.includes(cmr.productId)){
                                        filteredOngoingCMRs.push(cmr);
                                    }
                                
                                }
                            }

                    })

                }else{

                    filteredOngoingCSOs = allOngoingCSOs;
                    filteredOngoingCMRs = allOngoingCMRs;
                }

                setOngoingCSOs(filteredOngoingCSOs);
                setOngoingCMRS(filteredOngoingCMRs);
                setOnGoingEvents(props.state.findOngoingCSOs?filteredOngoingCSOs:filteredOngoingCMRs);
            }
            if(props.state.findhistoricCSOs || props.state.findhistoricCMRs){
                const currentDate = new Date();
                const pastDate = new Date();

          
                pastDate.setDate(currentDate.getDate() - 7);
                const pastDateStr = moment(pastDate).format("YYYY-MM-DD");
                const currentDateStr = moment(currentDate).format("YYYY-MM-DD");
                let filterObj = {
                    "products": "",
                    "offerings": [],
                    "regions": [],
                    "environments": [],
                    "date": [
                        pastDateStr,
                        currentDateStr
                    ]
                }
                let statusObj = {
                    "value": "all",
                    "label": "All Status"
                }
                let allhistoricCMRs:any = [], allhistoricCSOs:any = [];
                let historicEventDates: any = eventService.getIncidentsByDate(filterObj, "all","all",statusObj,undefined,undefined,true)
                historicEventDates.forEach((eventDate:any)=>{
                        eventDate.incidents.forEach((event:any)=>{
                            if(event.eventType.includes("maintenance")){
                                allhistoricCMRs.push(event);
                            }else{
                                allhistoricCSOs.push(event);}

                            })
                });
                
                 let filteredHistoricCSOs: any  =  [], filteredHistoricCMRs: any = [];
                if((props.state.findhistoricCSOs||props.state.findhistoricCMRs) && props.state?.regionprop?.length && props.state?.myCustomProperty?.length){
                    
                    let tempregArr:  any =[];
                    let tempPrdArr:  any = [];
                    props.state.regionprop.forEach((region:any)=>{if(region.label==="All"){tempregArr = region.value}else{tempregArr.push(region.value)}});
                    props.state.myCustomProperty.forEach((prd:any)=>{tempPrdArr.push(prd.value)});
                    allhistoricCSOs.forEach((cso:any)=>{
                            if(cso.regions  && Object.keys(cso.regions).length){
                                if(Object.keys(cso.regions).some(value => tempregArr.includes(value))){
                                    if(tempPrdArr.length && tempPrdArr.includes(cso.productId)){
                                        filteredHistoricCSOs.push(cso);
                                    }
                                
                                }
                            }

                    })
                    allhistoricCMRs.forEach((cmr:any)=>{
                            if(cmr.regions  && Object.keys(cmr.regions).length){
                                if(Object.keys(cmr.regions).some(value => tempregArr.includes(value))){
                                    if(tempPrdArr.length && tempPrdArr.includes(cmr.productId)){
                                        filteredHistoricCMRs.push(cmr);
                                    }
                                
                                }
                            }

                    })

                }else{
                    filteredHistoricCSOs = allhistoricCSOs;
                    filteredHistoricCMRs = allhistoricCMRs;
                }
                



                filteredHistoricCSOs = filteredHistoricCSOs.filter(
                (item:any, index:any, self:any) =>
                    index ===
                    self.findIndex(
                    (t:any) => t.incidentId === item.incidentId && t.productId === item.productId
                    )
                );
                 filteredHistoricCMRs = filteredHistoricCMRs.filter(
                (item:any, index:any, self:any) =>
                    index ===
                    self.findIndex(
                    (t:any) => t.incidentId === item.incidentId && t.productId === item.productId
                    )
                );
                setHistoricCSOs(filteredHistoricCSOs);
                setHistoricCMRs(filteredHistoricCMRs);
                setHistoricEvents(props.state.findhistoricCSOs?filteredHistoricCSOs:filteredHistoricCMRs);
            }
             setLoading(false);
              });

    },[]);

function restart(){
return <div className='restart-container'>
    

    <button className="show-more-link  restart-workflow" onClick={props.actionProvider.handleFindEvents} >
          Go back
    </button>

    <button className="show-more-link restart" onClick={props.actionProvider.handleOptions} >
          Start from top
    </button>

    <button className="show-more-link feedback" onClick={props.actionProvider.showFeedback} >
          Share feedback
    </button>
</div>
}
function redirectProduct(productId:any, eventId:any){
   redirectEvent(`/products/${productId}/${eventId}`);

}
function getOngoingCSOs(type:any){
let ongoingEvents = type === "CSO"? ongoingCSOs: ongoingCMRs;
return <div>{ongoingEvents.slice(0, visibleCount).map((csoEvent:any,index:any)=>{
    return <div><button key={index} onClick={()=>redirectProduct(csoEvent.productId,csoEvent.incidentId)} className="events-button">
        {csoEvent.name} | {ongoingCSOs?"Issue":"Maintenance"} #{csoEvent.incidentId}
        </button>
        
        </div>
})}
{visibleCount < ongoingEvents?.length && (
        <button className="show-more-link" onClick={showMore} >
          Show More
        </button>
      )}
{restart()}
</div>
}

function getEvents(events:any){

const flatEventList:any = events?.flatMap((eventObj:any) =>
  eventObj.productInfo.map((product:any) => ({
    id: eventObj.value,
    name: eventObj.label,
    productId: product.productId,
    productName: product.productName,
  }))
);





let ongoingEvents = flatEventList;

return <div>
{flatEventList?.slice(0, visibleCount).map((csoEvent:any,index:any)=>{
  
    return <div><button key={index} onClick={()=>redirectProduct(csoEvent.productId,csoEvent.id)} className="events-button">
        {csoEvent.productName} | {csoEvent.id.includes("CHG")?`Maintenance #${csoEvent.id}`:`Incident #${csoEvent.id}`}
        </button>
        
        </div>
        
   
})}
{visibleCount < ongoingEvents?.length && (
        <button className="show-more-link" onClick={() => showMoreEvents(flatEventList)} >
          Show More
        </button>
      )}
{restart()}
</div>
}

function getHistoricCSOs(type:any){
let historicEvents = type === "CSO"? historicCSOs: historicCMRs;
return <div>{historicEvents.slice(0, visibleCount).map((csoEvent:any,index:any)=>{
    return <div><button key={index} onClick={()=>redirectProduct(csoEvent.productId,csoEvent.incidentId)} className="events-button">
        {csoEvent.name} | {historicCSOs?"Issue":"Maintenance"} #{csoEvent.incidentId}
        </button>
        
        </div>
})}
{visibleCount < historicEvents.length && (
        <button className="show-more-link" onClick={showMoreHistoric} >
          Show More
        </button>
      )}
{restart()}
</div>
}

  return (
    <div>{loading?"loading...":
    props.state.findOngoingCSOs?
            ongoingCSOs.length? <div>
            {getOngoingCSOs("CSO")}
            </div>:<div>No ongoing issues{restart()}</div>
        :props.state.findOngoingCMRs?ongoingCMRs.length?<div> {getOngoingCSOs("CMR")}</div>:<div>No ongoing maintenances{restart()}</div>
        :
    props.state.findhistoricCSOs?
            historicCSOs.length? <div>
            {getHistoricCSOs("CSO")}
            </div>:<div>No events match your criteria {restart()}</div>
        :props.state.findhistoricCMRs?historicCMRs.length?<div> {getHistoricCSOs("CMR")}</div>:<div>No historic maintenances{restart()}</div>
        :props.state.findEventId && props.state.ongoingEvents?<>{getEvents(props.state.eventprop)}</>:<div></div>}
    </div>
  );
}