import parse from 'html-react-parser';
import { useHistory } from "react-router-dom";
import OMEGA from '../../../omega';
import { EventService } from '../../../models/event-service';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { ConfigContext } from "../../../App";

class ActionProvider {
  
  constructor(createChatBotMessage, setStateFunc, createCustomMessage) {
    this.createChatBotMessage = createChatBotMessage;
    this.setState = setStateFunc;
     this.createCustomMessage = createCustomMessage;
    
  }
  
  locales = useContext(ConfigContext).appConfig.languageData;
  eventDefaultValue = {
    element: "Personal Assistant",
    type: "Button",
    action: "click",
    widget: {
      name: "Personal Assistant",
      type: "button"
    },
    feature: "Personal Assistant Usage"
  }

  omega_capture(element, widget_name, widget_type){
    OMEGA.init().updateAndTrack("event", [{
      ...this.eventDefaultValue,
      element: element,
      widget: {
        name: widget_name,
        type: widget_type
      },
    }]);
  }

  closeChatbot = () => {
    const elements = document.getElementsByClassName('chatbot-icon');
    if (elements.length > 0) {
        elements[0].click(); 
    }
    this.omega_capture("Close Chatbot", "Personal Assistant", "Button");
    };
  
 
resetState = ()=>{

this.setState((state) => ({
      ...state,
      intentmap: false,
    }));
this.setState((state) => ({
      ...state,
      messagecustom: "",
    }));
this.setState((state) => ({
      ...state,
      productSupport: false,
    }));
this.setState((state) => ({
      ...state,
      supportredirect: false,
    }));
}

handleFindEvents = ()=>{
  this.setState((state) => ({
      ...state,
      findEventId: false,
      ongoingEvents: [],
      findOngoingCSOs: false,
      findOngoingCMRs: false,
      findhistoricCSOs: false,
      findhistoricCMRs: false,
    }));
  this.omega_capture("Find Events", "Personal Assistant", "Button");
const message = this.createChatBotMessage(parse(`Please select an option from below`),{
      widget: "eventoptions",
    });
    this.addMessageToState(message);
}
handleFeedbackSubmit = ()=>{
const message = this.createChatBotMessage(parse(`Your feedback has been recorded. Thank you.`));
    this.addMessageToState(message);
}

handleFeedbackFailure = ()=>{
const message = this.createChatBotMessage(parse(`Your feedback could not be recorded. Please try again.`));
    this.addMessageToState(message);
}

handleOptions = ()=>{
  this.setState((state) => ({
      ...state,
      findEventId: false,
      ongoingEvents: [],
      findOngoingCSOs: false,
      findOngoingCMRs: false,
      findhistoricCSOs: false,
      findhistoricCMRs: false,
    }));
  this.omega_capture("Launch ChatBot", "Personal Assistant", "Button");

const message = this.createChatBotMessage(parse(`Please select an option from below`),{
      widget: "options",
    });
    this.addMessageToState(message);
}

handleCloseBot = () => {
  this.omega_capture("Close Chatbot", "Personal Assistant", "Button");
  const message = this.createChatBotMessage(parse(`Goodbye! Closing the chatbot.`));
  this.addMessageToState(message);
  this.closeChatbot();
}

handleMoreHelp = ()=>{
  this.omega_capture("More Help", "Personal Assistant", "Button");
const message = this.createChatBotMessage(parse(`Please select the category that you are looking for`),{
      widget: "helpoptions",
    });
    this.addMessageToState(message);
}
handleNotifications = ()=>{
  this.omega_capture("Notifications", "Personal Assistant", "Button");
 this.setState((state) => ({
        ...state,
        notiisuse: true,
      }));
const message = this.createChatBotMessage(parse(`Please select an option from below`),{
      widget: "notioptions",
    });
    this.addMessageToState(message);
}

displaySubscriptions = (type)=>{
 if(type==="Notification"){
this.setState((state) => ({
        ...state,
        notiisuse: true,
      }));
  }else{
this.setState((state) => ({
        ...state,
        notiisuse: false,
      }));
  }
  this.omega_capture("Display Subscriptions", "Personal Assistant", "Button");
const message = this.createChatBotMessage(parse(`Please check your current subscriptions`),{
      widget: "inbotsub",
    });
    this.addMessageToState(message);
}

SubscriptionComplete = async()=>{
  this.omega_capture("Subscription Complete", "Personal Assistant", "Button");
const message = this.createChatBotMessage(parse(`Thank You.`),{
  widget: "closeoptions",
});
    this.addMessageToState(message);
    // this.showFeedback();
}

showFeedback = ()=>{
this.omega_capture("Feedback Form", "Personal Assistant", "form");
// if(!sessionStorage.getItem(('feedback')) || (sessionStorage.getItem(('feedback')) === "false" && parseInt(sessionStorage.getItem('feedbackCount')) < 3)){
const feedbackMessage = {
  type: 'feedback'
};
//  await this.sleep(3000);
    this.addMessageToState(feedbackMessage);
//}
}

findEventId = async()=>{
  this.omega_capture("Find Event Id", "Personal Assistant", "Button");
this.resetState();
  this.setState((state) => ({
        ...state,findEventId:true
}));
 const loadingMessage = this.createChatBotMessage(parse(`loading...`));
  this.addMessageToState(loadingMessage);
await this.getEventInfo("findEvent");
 const message = this.createChatBotMessage(parse(`Please enter the event you are interested in`),{
          widget: "eventdropdown",
 });
 this.removeLastMessage();
 this.addMessageToState(message);
}
findOngoingCSOs = async ()=>{
  this.omega_capture("Find Ongoing CSOs", "Personal Assistant", "Button");
this.resetState();
  this.setState((state) => ({
        ...state,
        findOngoingCSOs: true,
      }));
  const loadingMessage = this.createChatBotMessage(parse(`loading...`));
  this.addMessageToState(loadingMessage);
  var eventlength = await this.getEventInfo("onGoingCSOs");
  if(eventlength>0){
    const message = this.createChatBotMessage(parse(`Do you want to filer the results by Region and Product ?`),{
          widget: "filterconfirmation",
        });
    this.removeLastMessage();
    this.addMessageToState(message);
  }else{
    this.removeLastMessage();
    this.handleFilter();
  }
}

findOngoingCMRs = async()=>{
  this.omega_capture("Find Ongoing CMRs", "Personal Assistant", "Button");
this.resetState();
  this.setState((state) => ({
        ...state,
        findOngoingCMRs: true,
      }));
    const loadingMessage = this.createChatBotMessage(parse(`loading...`));
  this.addMessageToState(loadingMessage);
   var eventlength = await this.getEventInfo("onGoingCMRs");
    if(eventlength>0){
      const message = this.createChatBotMessage(parse(`Do you want to filer the results by Region and Product ?`),{
            widget: "filterconfirmation",
          });
        this.removeLastMessage();
      this.addMessageToState(message);
    }else{
       this.removeLastMessage();
      this.handleFilter();
    }
}

getEventInfo = (type)=>{

const eventService= EventService.init();
eventService.setLocales(this.locales);
const [servicePromise] = [eventService.fetch()];
    return Promise.all([servicePromise])
      .then(() => {
                let ongoingEvents = eventService.getOnGoingEventsByType();
               
                let ongoingCSOs = ongoingEvents.filter((event)=>!event.type.includes("maintenance"));
                let ongoingCMRs = ongoingEvents.filter((event)=>event.type.includes("maintenance"));
                let allOngoingCSOs = [];
                ongoingCSOs.forEach((cso)=>{cso.list.forEach((csoEvent)=>{allOngoingCSOs.push(csoEvent)})});
                let allOngoingCMRs = [];
                ongoingCMRs.forEach((cmr)=>{cmr.list.forEach((cmrEvent)=>{allOngoingCMRs.push(cmrEvent)})});
                 
                const currentDate = new Date();
                const pastDate = new Date();

          
                pastDate.setDate(currentDate.getDate() - 7);
                const pastDateStr = moment(pastDate).format("YYYY-MM-DD");
                const currentDateStr = moment(currentDate).format("YYYY-MM-DD");
                let filterObj = {
                    "products": "",
                    "offerings": [],
                    "regions": [],
                    "environments": [],
                    "date": [
                        pastDateStr,
                        currentDateStr
                    ]
                }
                const FortypastDate = new Date();
                FortypastDate.setDate(currentDate.getDate() - 40);
                const FortypastDateStr = moment(FortypastDate).format("YYYY-MM-DD");
                let FortyDayFilterObj = {
                    "products": "",
                    "offerings": [],
                    "regions": [],
                    "environments": [],
                    "date": [
                        FortypastDateStr,
                        currentDateStr
                    ]
                }
                let statusObj = {
                    "value": "all",
                    "label": "All Status"
                }
                let allhistoricCMRs = [], allhistoricCSOs = [], FortyDayEvents = [];
                let historicEventDates = eventService.getIncidentsByDate(filterObj, "all","all",statusObj,undefined,undefined,true)
                historicEventDates.forEach((eventDate)=>{
                        eventDate.incidents.forEach((event)=>{
                            if(event.eventType.includes("maintenance")){
                                allhistoricCMRs.push(event);
                            }else{
                                allhistoricCSOs.push(event);}

                            })
                });


              let FortyDayhistoricEventDates = eventService.getIncidentsByDate(FortyDayFilterObj, "all","all",statusObj,undefined,undefined,true)
                // console.log("FORTY DAY EVENTS",FortyDayhistoricEventDates);
                FortyDayhistoricEventDates.forEach((eventDate)=>{
                        eventDate.incidents.forEach((event)=>{
                          
                                FortyDayEvents.push(event);
                  
                        }

                            )
                });
            if(type==="findEvent"){ this.setState((state) => ({ ...state, ongoingEvents:FortyDayEvents}));}
           

      if(type==="onGoingCSOs"){return allOngoingCSOs.length;}
      else if(type === "onGoingCMRs"){return allOngoingCMRs.length;}
      else if(type === "historicCMRs"){return allhistoricCMRs.length;}
      else if(type === "historicCSOs"){return allhistoricCSOs.length;}
      
                
  });

}

findLast7DaysCSOs = async()=>{
  this.omega_capture("Find Last 7 Days CSOs", "Personal Assistant", "Button");
this.resetState();
  this.setState((state) => ({
        ...state,
        findhistoricCSOs: true,
      }));
  const loadingMessage = this.createChatBotMessage(parse(`loading...`));
  this.addMessageToState(loadingMessage);
   var eventlength = await this.getEventInfo("historicCSOs");
    if(eventlength>0){
      const message = this.createChatBotMessage(parse(`Do you want to filer the results by Region and Product ?`),{
            widget: "filterconfirmation",
          });
        this.removeLastMessage();
      this.addMessageToState(message);
    }else{
       this.removeLastMessage();
      this.handleFilter();
    }
}

findLast7DaysCMRs = async()=>{
  this.omega_capture("Find Last 7 Days CMRs", "Personal Assistant", "Button");
this.resetState();
  this.setState((state) => ({
        ...state,
        findhistoricCMRs: true,
      }));
  const loadingMessage = this.createChatBotMessage(parse(`loading...`));
  this.addMessageToState(loadingMessage);
  var eventlength = await this.getEventInfo("historicCMRs");

    if(eventlength>0){
      const message = this.createChatBotMessage(parse(`Do you want to filer the results by Region and Product ?`),{
        widget: "filterconfirmation",
      });
        this.removeLastMessage();
      this.addMessageToState(message);
    }else{
       this.removeLastMessage();
      this.handleFilter();
    }
}

showFilter = ()=>{
  this.omega_capture("Show Filter", "Personal Assistant", "Button");
  this.setState((state) => ({
        ...state,
      }));
  const message = this.createChatBotMessage(parse(`Please select a region and product`),{
        widget: "regiondropdown",
      });
  this.addMessageToState(message);
}
sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
handleFilter = async(regionVal,productVal)=>{
  const region= regionVal?regionVal:"All";
  const product= productVal?productVal:"All";
  this.omega_capture("Filter - "+region+" - "+product, "Personal Assistant", "Button");
  this.setState((state) => ({
        ...state,
        regionprop: regionVal,
        myCustomProperty: productVal
      }));


  const message = this.createChatBotMessage(parse(`Here's a list of Events`),{
        widget: "showevents",
      });
  this.addMessageToState(message);
  // this.showFeedback();
}

handleEventFilter = async(eventId)=>{
  this.omega_capture("Event Filter - "+eventId, "Personal Assistant", "Button");
this.setState((state) => ({
        ...state,
        eventprop: eventId,
      
      }));

const message = this.createChatBotMessage(parse(`Here's a list of Events`),{
        widget: "showevents",
      });
  this.addMessageToState(message);
  //  this.showFeedback();
}

  addMessageToState = (message) => {
    this.setState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages, message]
    }));
  };
  removeLastMessage=()=>{
this.setState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages.slice(0, -1)]
    }));
  }
}

export default ActionProvider;



